<template>
  <div class="content">
    <div class="toptitle">
      <span class="size36">企业责任</span>
      <p>Responsibility</p>
    </div>
    <div class="fix" @click="btn()">关于我们 &ensp;> &ensp;社会责任</div>
    <div class="title1">共克时艰 捐款助力疫情防控</div>
    <div class="box"></div>
    <div class="title2">
      “一方有难，八方支援”，面对突如其来的新冠疫情，和行公司高度重视，向广大党员干部职工发出为疫区捐款奉献爱心的倡议，公司领导率先垂范，带头捐款，干部职工积极响应，参与到这次募捐活动中来，大家通过银行转账、微信捐款以及现金捐赠等形式奉献爱心，纷纷为抗击疫情贡献一份绵薄之力，共计93名干部职工捐款，捐款金额20000元。
    </div>
    <img src="../../../static/images/about/2.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    btn() {
      this.$router.push({ name: "pcsocial" });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-weight: 400;
  color: #2d2d39;
  font-size: 14px;
  .toptitle {
    box-sizing: border-box;
    padding: 6% 0 0 4%;
    .size36 {
      font-size: 36px;
    }
    p {
      margin-left: 1px;
      font-size: 16px;
    }
  }
  .fix {
    cursor: pointer;
    width: 100%;
    height: 43px;
    line-height: 43px;
    margin-top: 10px;
    padding-left: 4%;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  .title1 {
    width: 61.8%;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    margin: 45px 0 0 4%;
  }
  .box {
    width: 61.8%;
    height: 1px;
    background-color: #e9e9e9;
    margin: 15px 0 0 4%;

  }
  .title2 {
    line-height: 25px;
    width: 61.8%;
    margin: 35px 0 0 4%;
  }
  img {
    width: 61.8%;
    margin: 30px 0 0 4%;
  }
}
</style>